export default {
  computed: {
    isUnavailable() {
      if (this.variant) return !this.variant.available
      return !this.product.available
    },
    isLowInStock() {
      if (this.variant) {
        return this.variant.inventory_policy == 'deny' && this.variant.inventory_quantity < 4
      }

      if (this.product.variants.find((v) => v.inventory_policy != 'deny')) return false

      const totalQuantity = this.product.variants.reduce((c, v, k) => {
        c = c + v.inventory_quantity
        return c
      }, 0)

      return totalQuantity < 4
    },
    isComingSoon() {
      return this.isUnavailable && this.isPreorder
    },
    isPreorder() {
      return this.product?.tags?.find((t) => t == 'preorder')
    },
  },
}
