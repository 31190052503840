<template>
  <form class="product-buy" @submit.prevent="onSubmit">
    <button class="product-buy__button" :disabled="!isAvailable">
      {{ getText }}
    </button>
    <div class="product-buy__shipping" v-html="settings.product_shipping_text"></div>

    <div
      v-if="settings.product_preorder_text && isPreorder"
      class="product-buy__preorder"
      v-html="settings.product_preorder_text"
    ></div>

    <ProductNotify
      v-if="isNotify"
      class="product-buy__notify"
      :product="product"
      :variant="selectedVariant"
    />
  </form>
</template>

<script>
import Vuex from 'vuex'
import { klaviyoAddToCart } from '../../lib/klaviyo'
import ProductNotify from './ProductNotify.vue'
import ProductStockMixin from '../../mixins/productStock.js'

export default {
  data() {
    return {
      isAdding: false,
    }
  },
  computed: {
    ...Vuex.mapState({
      cart: (state) => state.cart.cart,
      product: (state) => state.product.product,
      settings: (state) => state.shopify.settings,
      selectedVariant: (state) => state.product.selectedVariant,
    }),
    isAvailable() {
      return this.selectedVariant?.available
    },
    tags() {
      return this?.product?.tags || []
    },
    getText() {
      if (this.isAdding) return 'Adding to cart'
      if (this.isPreorder) return this.isAvailable ? 'Preorder' : 'Coming Soon'
      return this.isAvailable ? 'Add to Cart' : 'Sold Out'
    },
    isNotify() {
      const isNotifiable = this.tags.find((t) => t == 'notifiable')
      return isNotifiable && !this.isAvailable
    },
    isPreorder: ProductStockMixin.computed.isPreorder,
    isComingSoon: ProductStockMixin.computed.isComingSoon,
  },
  methods: {
    async onSubmit() {
      if (!this.isAvailable) return
      this.isAdding = true
      try {
        await this.$store.dispatch('cart/add', {
          id: this.selectedVariant.id,
        })

        await klaviyoAddToCart({
          shopifyProduct: this.product,
          shopifyVariant: this.selectedVariant,
          quantity: 1,
          cart: this.cart,
        })
        this.$store.commit('overlay/open', 'cart')
      } catch (e) {
        console.log(e)
      }
      this.isAdding = false
    },
  },
  components: { ProductNotify },
}
</script>

<style lang="scss">
@import '~styles/base.scss';

.product-buy {
  &__button {
    @include form-button();
    width: 100%;
    margin-bottom: 12px;

    &:disabled {
      color: $lightgrey;
      cursor: auto;
      &:hover {
        background: $background;
      }
    }
  }

  &__shipping {
    @include cx-xxs;
    @include helper-link-underline(1);
  }

  &__preorder {
    @include cx-xs;
    margin-top: 1em;
  }

  &__notify {
    margin-top: 1em;
  }
}
</style>
