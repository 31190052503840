<template>
  <span class="shopify-product-price">
    <div v-if="variantSalePrice || productSalePrice" class="shopify-product-price__sale">
      <ShopifyMoney
        :hide-all-currency="hideAllCurrency"
        class="shopify-product-price_sale__amount"
        :amount="variantSalePrice || productSalePrice"
      />
    </div>

    <ShopifyMoney
      v-show="variantPrice || productPrice"
      class="shopify-product-price__amount"
      :hide-all-currency="hideAllCurrency"
      :amount="variantPrice || productPrice || 0"
    />

    <div
      v-if="isComingSoon"
      class="shopify-product-price__message shopify-product-price__message--comingsoon"
      v-text="comingSoonMessage"
    ></div>

    <div
      v-else-if="isUnavailable"
      class="shopify-product-price__message shopify-product-price__message--sold"
      v-text="soldOutMessage"
    ></div>

    <div
      v-else-if="variantOnSale || productOnSale"
      class="shopify-product-price__message shopify-product-price__message--sale"
      v-text="onSaleMessage"
    ></div>

    <div
      v-else-if="isLowInStock"
      class="shopify-product-price__message shopify-product-price__message--low"
      v-text="lowInStockMessage"
    ></div>

    <div
      v-else-if="isPreorder"
      class="shopify-product-price__message shopify-product-price__message--preorder"
      v-text="preorderMessage"
    ></div>
  </span>
</template>

<script>
// import MoneyMixin from '~/mixins/money'
import ProductMixin from '~/mixins/product'
import ShopifyMoney from '~/components/shopify/ShopifyMoney.vue'

export default {
  components: { ShopifyMoney },
  mixins: [ProductMixin],
  props: {
    hideAllCurrency: {
      type: Boolean,
      default: false,
    },
    soldOutMessage: {
      type: String,
      default: 'Sold Out',
    },
    lowInStockMessage: {
      type: String,
      default: 'Almost gone',
    },
    onSaleMessage: {
      type: String,
      default: 'Final Sale',
    },
    preorderMessage: {
      type: String,
      default: 'Preorder',
    },
    comingSoonMessage: {
      type: String,
      default: 'Coming Soon',
    },
  },
}
</script>

<style lang="scss">
.shopify-product-price {
  display: inline-flex;
  flex-wrap: wrap;
  gap: 0px 3px;

  &__sale {
    position: relative;

    &:after {
      position: absolute;
      content: '';
      left: 0;
      right: 0;
      top: 39%;
      border-top: 1px solid currentColor;
    }
  }

  &__money {
    display: inline-flex;
  }

  &__message {
    display: inline-flex;

    &--low,
    &--sale,
    &--comingsoon,
    &--preorder {
      color: var(--color-lightgrey);
    }
  }
}
</style>
